import dayjs from 'dayjs';
import {
  DefaultFetchError,
  FetchCreate, FetchGetId, FetchUpdate,
  useFetchCreate, useFetchGetId, useFetchUpdate,
} from '../fetch';
import { CollectionByIdGet } from './collection';

export interface ShareGet extends CollectionByIdGet {
  updatedAt: dayjs.Dayjs;
  expiration: dayjs.Dayjs;
}

export const useShareGet = (id = ''): FetchGetId<ShareGet, string> => useFetchGetId(
  'share',
  id,
  {
    autoStart: false,
    startStateLoading: true,
    latest: true,
    decorateData: (data) => ({
      ...data,
      updatedAt: dayjs(data.updatedAt),
      expiration: dayjs(data.expiration),
    }),
  },
);

export type ShareExtendGet = ShareGet

export const useShareExtend = (id = ''): FetchUpdate<ShareExtendGet> => useFetchUpdate(
  'share/extend',
  id,
  {
    autoStart: false,
    startStateLoading: false,
    latest: true,
  },
);

export interface ShareResponse {
  id: string;
  expiration?: string; // Date
}

export interface ShareCreateProps {
  expiration?: string; // Date
  collectionId: string;
  assets: string[]; // list id
}

export const useShareCreate = (): FetchCreate<
  ShareResponse,
  DefaultFetchError,
  ShareCreateProps
> => useFetchCreate(
  'share',
  {
    startStateLoading: false,
  },
);
