import React from 'react';
import { Pagination } from 'antd';
import ContentWrapper from '../../Common/Content/Wrapper';
import Filter from '../../Common/Filter';
import AssetWrapper from '../../Common/Asset/Wrapper';
import Asset from '../../Common/Asset';
import {
  ArrowLeftIcon, ArrowRightIcon,
} from '../../Common/Icon';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useFilter } from './Context/filter';
import SelectedFilters from '../../Common/SelectedFilters';
import Loading from '../../Common/Loading';

import styles from './index.module.scss';

export default function Home(): React.ReactNode {
  const [_, setSearchParams, searchParams] = useSearchParams({}, false);
  const {
    state, total, assetGet,
  } = useFilter();

  return (
    <ContentWrapper>
      <Filter>
        <SelectedFilters />

        <AssetWrapper>
          {assetGet.data?.data.map((asset) => (
            <Asset key={asset.assetId} to={`asset/${asset.assetId}`} asset={asset} />
          ))}
        </AssetWrapper>

        <div className={styles.pagination}>
          <Pagination
            onChange={(value) => setSearchParams({
              ...searchParams,
              page: value.toString(),
            })}
            current={state.page}
            total={total}
            pageSize={state.pageSize}
            showSizeChanger={false}
            hideOnSinglePage
            showTitle={false}
            nextIcon={<ArrowRightIcon />}
            prevIcon={<ArrowLeftIcon />}
          />
        </div>
      </Filter>

      {assetGet.loading ? (
        <Loading />
      ) : null}

    </ContentWrapper>
  );
}
