import React from 'react';

import { useTranslation } from 'react-i18next';
import NoData from '../../Common/Wrapper/NoData';

import styles from './index.module.scss';

export default function NoAssetsInCollection(): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className={styles.notCollection}>
      <NoData
        type="noCollection"
        title={(
          <span style={{ fontWeight: 300 }}>
            {t('noData.noCollection.asset.title')}
          </span>
        )}
      />
    </div>
  );
}
