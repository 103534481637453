import dayjs from 'dayjs';
import {
  FetchGet, FetchGetId, PagingDataResponse, PagingParams, useFetchGet, useFetchGetId,
} from '../fetch';
import {
  AssetAllData,
  AssetFilterItem,
  GetBrandResponse,
  GetCampaignResponse,
  GetKeywordResponse,
  GetLocationResponse,
  GetPersonResponse,
  GetSeriesResponse,
  GetStatementResponse,
  GetSurroundingResponse, GetSystemResponse, GetToolsResponse, GetTypeResponse,
} from '../../types/asset';
import { AnyObject } from '../../types';
import { PointColor } from '../../components/Common/Point';

export function getFileSize(data?: AssetGet | AssetByIdGet, exportType = 'export') {
  return Number.parseInt(data?.allData?.files?.find(({ type }) => type === exportType)?.filelength || '', 10) / 1024;
}

export interface AssetGetParams extends PagingParams {
  lang?: string;
  search?: string;
}

export interface AssetGet {
  id: string;
  assetId: string;
  name: string;
  restriction: PointColor;
  description: string;
  mimetype: string;
  urlPreview: string;
  urlExport: string;
  urlMaster: string;
  allData: AssetAllData;
  updatedAt: string;
}

export const useAssetGet = (): FetchGet<PagingDataResponse<AssetGet>, AssetGetParams> => useFetchGet(
  'asset',
  {
    autoStart: false, startStateLoading: false,
  },
);

export interface AssetByIdGet extends Omit<AssetGet, 'updatedAt'> {
  mediaType: string;
  format: string;
  language: string;
  publicationType: string;
  publicationYear: string;
  reference: string;
  event: string;
  allData: AssetAllData;
  brands: GetBrandResponse[];
  campaigns: GetCampaignResponse[];
  keywords: GetKeywordResponse[];
  locations: GetLocationResponse[];
  persons: GetPersonResponse[];
  series: GetSeriesResponse[];
  statements: GetStatementResponse[];
  surroundings: GetSurroundingResponse[];
  systems: GetSystemResponse[];
  tools: GetToolsResponse[];
  types: GetTypeResponse[];
  createdAt: string;
  updatedAt: dayjs.Dayjs;
}

export const useAssetByIdGet = (): FetchGetId<AssetByIdGet, string> => useFetchGetId(
  'asset',
  '',
  {
    autoStart: false,
    startStateLoading: false,
    latest: true,
    decorateData: (data) => ({ ...data, updatedAt: dayjs(data.updatedAt) }),
  },
);

export interface AssetFilterGet extends AnyObject {
  pagination: string[];

  order: string[];

  mediaType: string[];

  restriction: string[];

  format: string[];

  language: string[];

  publicationType: string[];

  publicationYear: string[];

  reference: string[];

  event: string[];

  brands: AssetFilterItem[];

  campaigns: AssetFilterItem[];

  keywords: AssetFilterItem[];

  locations: AssetFilterItem[];

  persons: AssetFilterItem[];

  series: AssetFilterItem[];

  statements: AssetFilterItem[];

  surroundings: AssetFilterItem[];

  systems: AssetFilterItem[];

  tools: AssetFilterItem[];

  types: AssetFilterItem[];
}

export const useAssetFilterGet = (): FetchGet<AssetFilterGet, null> => useFetchGet(
  'asset/filter-all-data',
  {
    autoStart: false, startStateLoading: false, latest: true,
  },
);

export interface AssetFilterAvailableGet {
  [key: string]: string[]
}

export const useAssetFilterAvailableGet = (): FetchGet<AssetFilterAvailableGet, AssetGetParams> => useFetchGet(
  'asset/available-filter',
  {
    autoStart: false, startStateLoading: false, latest: true,
  },
);

export default {
  useAssetGet,
  useAssetFilterGet,
  useAssetFilterAvailableGet,
};
