import React from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { AssetByIdGet, getFileSize } from '../../../../hooks/api/asset';
import { humanFileSize } from '../../../../utils';
import { ArrowBottomIcon, TrashIcon } from '../../../Common/Icon';
import ImgBackground from '../../../Common/ImgBackground';

import styles from './index.module.scss';

export interface SelectedListProps {
  assets: AssetByIdGet[];
  listChecked: [string, ListSize][];
  setListChecked: (list: [string, ListSize][]) => void;
}

export type ListSize = 'preview' | 'export' | 'master';
export const sizeAssetAlias: { [key in ListSize]: string } = {
  preview: 'p',
  export: 'e',
  master: 'm',
};

export default function SelectedList({
  assets,
  listChecked,
  setListChecked,
}: SelectedListProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      {assets.map((data) => {
        const {
          id, name, urlPreview, urlExport, mediaType, mimetype, allData,
        } = data;
        const checked = listChecked.find(([checkedId]) => checkedId === id);
        const fileInfo = checked ? allData.files.find(({ type }) => type === checked[1]) : null;
        const url = urlPreview || urlExport;

        return (
          <div key={id} className={styles.asset}>
            <div className={styles.row}>
              <div>
                <ImgBackground url={url || '/resource/images/asset.png'} className={styles.img} />
              </div>

              <div className={styles.info}>
                <div className={styles.name}>
                  {name}
                </div>
                <div className={styles.type}>
                  <span>{mediaType}</span>
                  <span>{mimetype}</span>
                </div>
                <div className={styles.line} />
                {fileInfo ? (
                  <div className={styles.fileInfo}>
                    {fileInfo.widthPx ? (
                      <span>
                        {fileInfo.widthPx}
                        px
                      </span>
                    ) : null}

                    {fileInfo.dpi ? (
                      <span>
                        {fileInfo.dpi}
                        dpi
                      </span>
                    ) : null}

                    <span>{humanFileSize(getFileSize(data, checked ? checked[1] : undefined))}</span>
                  </div>
                ) : null}
              </div>
            </div>

            <TrashIcon
              className={styles.remove}
              onClick={() => {
                setListChecked(listChecked.filter(([assetId]) => assetId !== id));
              }}
            />

            <div className={styles.size}>
              <span>
                {t('yourDownloadItems')}
              </span>
              <Select
                size="large"
                className={styles.select}
                suffixIcon={<ArrowBottomIcon />}
                onChange={(value) => {
                  const newListChecked = [...listChecked];
                  const check = newListChecked.find(([assetId]) => assetId === id);

                  if (check) {
                    check[1] = value;
                  }

                  setListChecked(newListChecked);
                }}
                value={listChecked.find(([assetId]) => assetId === id)?.[1]}
              >
                <Select.Option value="preview">
                  {t('preview')}
                </Select.Option>
                <Select.Option value="export">
                  {t('export')}
                </Select.Option>
                <Select.Option value="master">
                  {t('master')}
                </Select.Option>
              </Select>
            </div>
          </div>
        );
      })}
    </div>
  );
}
