import React from 'react';
import Layout from '../../../components/Layout';
import ContentCollection from '../../../components/Pages/Collections/Collection';
import CollectProvider from '../../../components/Pages/Collections/Collection/Context';

export default function Collection(): React.ReactNode | null {
  document.title = 'Collection';

  return (
    <CollectProvider>
      <Layout className="center">
        <ContentCollection />
      </Layout>
    </CollectProvider>
  );
}
