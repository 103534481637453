import {
  createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { AnyObject } from '../types';
import { sizeAssetAlias } from '../components/Pages/Share/SelectedList';

function createPrepareFileQuery(props: PrepareProps): string {
  let params = '';

  if (props) {
    const list = Object.keys(props);

    list.forEach((key, i) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data: string[] = props[key];

      if (data.length > 0) {
        switch (key) {
          case 'ids': {
            params += (new URLSearchParams(data.map((id) => [sizeAssetAlias.export, id])).toString());

            break;
          }
          case 'p': {
            params += (new URLSearchParams(data.map((id) => [sizeAssetAlias.preview, id])).toString());

            break;
          }
          case 'e': {
            params += (new URLSearchParams(data.map((id) => [sizeAssetAlias.export, id])).toString());

            break;
          }
          case 'm': {
            params += (new URLSearchParams(data.map((id) => [sizeAssetAlias.master, id])).toString());

            break;
          }

          default: break;
          // No default
        }

        if (list.length - 1 !== i) {
          params += '&';
        }
      }
    });
  }

  return params;
}

export interface PrepareProps {
  ids?: string[];
  p?: string[];
  e?: string[];
  m?: string[];
}

interface List {
  size: number;
  id: string;
}

interface Response {
  type: string;
  error?: AnyObject | null;
  asset?: { id: string };
  assets?: List[];
}

interface PrepareFile {
  id: number;
  name: string;
  close: boolean;
  loading: boolean;
  error: AnyObject | null;
  success: boolean;
  props: PrepareProps;
  queryParams: string;
  responses: Response[];
  assets: List[];
  closeConnection: () => void;
}

interface PrepareFileProviderProps {
  list: PrepareFile[];
  remove: (id: number) => void;
  prepare: (props: PrepareProps, name: string) => number;
}

const defaultValue: PrepareFileProviderProps = {
  list: [],
  remove: (id: number) => {
    // ...
  },
  prepare: (props: PrepareProps) => 0,
};

export const PrepareFileContext = createContext<PrepareFileProviderProps>(defaultValue);

function PrepareFileProvider({ children }: { children: ReactNode }) {
  const [list, setList] = useState<PrepareFile[]>([]);
  const refList = useRef<PrepareFile[]>([]);

  const memoizedValue = useMemo(() => ({
    list,
    remove: (id: number) => {
      const connect = refList.current.find((_, i) => i === id);

      if (connect) {
        if (connect.closeConnection) {
          connect.closeConnection();
        }

        connect.close = true;
      }

      refList.current = [...refList.current];

      setList(() => refList.current);
    },
    prepare: (props: PrepareProps, name = '') => {
      const queryParams = createPrepareFileQuery(props);
      const eventSource = new EventSource(`${process.env.REACT_APP_API}file/prepare?${queryParams}`);
      const id = refList.current.length;
      const onMessage = ({ data }: any) => {
        try {
          const response = JSON.parse(data) as Response;
          const { type, error, assets } = response;
          const newList = [...refList.current];
          const newState: PrepareFile = { ...(newList[id] || { responses: [] }) };

          switch (type) {
            case 'size':
              Object.assign(newState, {
                assets,
              });
              break;

            case 'error':
              Object.assign(newState, {
                error,
              });
              break;

            case 'close':
              Object.assign(newState, {
                loading: false,
                success: true,
              });
              eventSource.removeEventListener('message', onMessage);
              eventSource.close();
              break;

            default: {
              Object.assign(newState, {
                responses: newState.responses.some(({ asset }) => asset?.id === response?.asset?.id)
                  ? newState.responses
                  : [...newState.responses, response],
              });
            }
          }

          newList[id] = newState;

          refList.current = newList;
          setList(refList.current);
        } catch (error) {
          console.warn(error);
        }
      };

      refList.current = [...refList.current, {
        id,
        name,
        close: false,
        loading: true,
        error: null,
        success: false,
        assets: [],
        props,
        queryParams,
        responses: [],
        closeConnection: () => {
          eventSource.removeEventListener('message', onMessage);
          eventSource.close();
        },
      }];

      setList(refList.current);

      eventSource.addEventListener('message', onMessage, false);

      return id;
    },
  }), [list]);

  return (
    <PrepareFileContext.Provider value={memoizedValue}>
      {children}
    </PrepareFileContext.Provider>
  );
}

export default PrepareFileProvider;

export const usePrepareFile = (): PrepareFileProviderProps => useContext(PrepareFileContext);

export const usePrepareFileId = (): {
  state: PrepareFile;
  setId: (id: number) => void;
  remove: () => void;
} => {
  const { list, remove } = usePrepareFile();
  const [id, setId] = useState<number>();
  const [state, setState] = useState<PrepareFile>({
    id: 0,
    name: '',
    close: false,
    assets: [],
    loading: false,
    success: false,
    responses: [],
    error: null,
    props: {
      ids: [],
      p: [],
      e: [],
      m: [],
    },
    queryParams: '',
    closeConnection: () => {},
  });

  useEffect(() => {
    if (id !== undefined && list[id]) {
      setState(list[id]);
    }
  }, [list]);

  return useMemo(() => ({
    state,
    setId,
    remove: () => {
      if (id !== undefined) {
        remove(id);
      }
    },
  }), [state, id]);
};
