import React from 'react';

import clsx from 'clsx';

import styles from './index.module.scss';

export interface NoDataProps {
  type: 'noResult' | 'noFound' | 'noCollection';
  title: string | React.ReactNode;
  text?: string | React.ReactNode;
  action?: React.ReactNode;
  link?: React.ReactNode;
}

export default function NoData({
  type,
  title,
  text,
  action,
  link,
}: NoDataProps): React.ReactNode {
  return (
    <div className={styles.content}>
      <div className={styles.text}>
        <div className={styles.uppercase}>
          {title}
        </div>

        {text ? (
          <span style={{ padding: '8px 0', fontSize: 16 }}>
            {text}
          </span>
        ) : null}

        {action ? (
          <div>
            {action}
          </div>
        ) : null}

        {link ? (
          <div>
            {link}
          </div>
        ) : null}
      </div>
      <div className={clsx(styles.img, styles[type])} />
    </div>
  );
}
