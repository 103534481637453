import React from 'react';

export interface ImgBackgroundProps {
  className: string;
  url?: string
}

export default function ImgBackground({ className, url }: ImgBackgroundProps): React.ReactNode {
  return (
    <>
      <div
        className={className}
        style={{ backgroundImage: `url('${url || '/resource/images/asset.png'}')` }}
      />
      <img
        alt={url}
        title={url}
        src={url || '/resource/images/asset.png'}
        style={{
          opacity: 0, position: 'absolute', width: 1, pointerEvents: 'none', display: 'none',
        }}
        onLoad={(e: any) => {
          const { width, height } = e.target.getBoundingClientRect();

          if (e.target) {
            if (e.target.previousSibling) {
              e.target.previousSibling.style.aspectRatio = `1/${Math.max(Math.min(height / width, 2), 0.5)}`;
            }
            e.target.remove();
          }
        }}
        onError={(e: any) => {
          if (e.target && e.target.previousElementSibling) {
            e.target.previousElementSibling.style.backgroundImage = ''
              + 'url(\'/resource/images/asset.png\')';
          }
        }}
      />
    </>
  );
}
