import React from 'react';
import clsx, { ClassValue } from 'clsx';

import { InfoIcon } from '../../../../Common/Icon';
import Point from '../../../../Common/Point';
import { AssetByIdGet } from '../../../../../hooks/api/asset';
import List from '../List';
import { LinkWrapperProps } from '../Description';

export interface RightsProps {
  data?: AssetByIdGet;
  className?: string | ClassValue;
}

function addRights() {
  // eslint-disable-next-line func-names
  return function ({ text, parent }: LinkWrapperProps) {
    return (
      <div>
        <Point status={parent?.status || 'green'} text={text} />
      </div>
    );
  };
}

export default function Rights({
  data = undefined, className = '',
}: RightsProps): React.ReactNode {
  if (!data?.allData?.usageRestrictions) {
    return null;
  }

  return (
    <div className={clsx(className)}>
      <List
        data={data?.allData?.usageRestrictions}
        iconAfterName={<InfoIcon />}
        wrapper={addRights()}
      />
    </div>
  );
}
