import React, { useMemo, useState } from 'react';
import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContentWrapper from '../../../Common/Content/Wrapper';
import Breadcrumbs from '../../../Common/Breadcrumbs';
import {
  DownloadIcon,
  EditIcon, TrashIcon,
} from '../../../Common/Icon';
import { useCollectProvider } from './Context';
import NoAssetsInCollection from '../../NoAssetsInCollection';
import EditModal from '../../../Common/Modal/Collections/Edit';
import DeleteModal from '../../../Common/Modal/Collections/Delete';
import NotFound from '../../NotFound';
import Loading from '../../../Common/Loading';
import CollectionContent from './Content';
import NoResult from '../../NoResult';
import Download from './Modal/Download';
import { AssetByIdGet } from '../../../../hooks/api/asset';
import Share from './Modal/Share';
import { ListSize } from '../../Share/SelectedList';

import styles from './index.module.scss';

function DownloadButton(props: any) {
  const { t } = useTranslation();

  return (
    <Button
      icon={<DownloadIcon />}
      type="primary"
      size="large"
      {...props}
    >
      {t('download')}
    </Button>
  );
}

export default function Collection(): React.ReactNode {
  const { t } = useTranslation();
  const [listChecked, setListChecked] = useState<[string, ListSize][]>([]);
  const { collection, fetch } = useCollectProvider();
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [isModalOpenDelete, setModalOpenDelete] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const showModalEdit = () => {
    setIsModalOpenEdit(true);
  };

  const okEdit = () => {
    setIsModalOpenEdit(false);
    fetch();
  };

  const cancelEdit = () => {
    setIsModalOpenEdit(false);
  };

  const showModalDelete = () => {
    setModalOpenDelete(true);
  };

  const okDelete = () => {
    setModalOpenDelete(false);
    navigate('/collection');
  };

  const cancelDelete = () => {
    setModalOpenDelete(false);
  };

  const assetsChecked = useMemo(() => listChecked.map(([idChecked]) => collection.data?.assets
    .find(({ id: uuid }) => uuid === idChecked))
    .filter((value) => value) as AssetByIdGet[], [listChecked, collection.data?.assets]);

  return (
    <ContentWrapper className={styles.content}>
      <Breadcrumbs
        loading={false}
        list={[
          {
            name: t('collections'),
            to: '/collection',
          },
          {
            name: collection.data?.name || (collection.error ? 'Not found' : 'loading...'),
          },
        ]}
      />

      {!collection.loading ? (
        <div className={styles.header}>
          <div className={styles.title}>
            {collection.data?.name}
          </div>
          <div className={styles.description} style={{ fontSize: 16, fontWeight: 400 }}>
            {collection.data?.description}
          </div>

          <div className={styles.counter}>
            {collection.data?.assets.length}
            {' '}
            {t('media')}
            {' '}
            {(collection.data?.assets.length || 0) > 1 ? t('assets') : t('asset')}

            <div className={styles.actions}>
              {assetsChecked.length > 0 ? (
                <Download
                  name={collection.data?.name || ''}
                  assets={assetsChecked}
                  button={DownloadButton}
                />
              ) : null}

              <Share id={id} assets={assetsChecked} />

              <Button
                icon={<EditIcon />}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  showModalEdit();
                }}
              >
                {t('edit')}
              </Button>
              <EditModal
                key={JSON.stringify(collection.data)}
                collection={collection.data}
                isModalOpen={isModalOpenEdit}
                handleOk={okEdit}
                handleCancel={cancelEdit}
              />

              <Button
                icon={<TrashIcon />}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  showModalDelete();
                }}
              >
                {t('delete.title')}
              </Button>
              <DeleteModal
                id={id}
                isModalOpen={isModalOpenDelete}
                handleOk={okDelete}
                handleCancel={cancelDelete}
              />
            </div>
          </div>
        </div>
      ) : null}

      {/* eslint-disable-next-line no-nested-ternary */}
      {!collection.data && !collection.loading ? (
        collection.error ? <NotFound /> : <NoAssetsInCollection />
      ) : (
        <>
          {/* eslint-disable-next-line no-nested-ternary */}
          {collection.error ? (
            <NotFound />
          ) : (
            collection.data?.assets.length || collection.loading ? (
              <CollectionContent
                listChecked={listChecked}
                setListChecked={setListChecked}
                collection={collection.data}
                actions={{ delete: () => fetch() }}
              />
            ) : (
              <NoResult style={{ marginTop: 24 }} />
            )
          )}
        </>
      )}

      {collection.loading ? <Loading /> : null}
    </ContentWrapper>
  );
}
