import React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './index.module.scss';

export type PointColor = 'orange' | 'green' | 'red' | 'default'

export interface ContentWrapperProps {
  className?: string | ClassValue;
  status?: PointColor;
  text?: string | React.ReactNode;
}

export default function Point({
  className = '',
  status = 'default',
  text,
}: ContentWrapperProps): React.ReactNode {
  if (!status) {
    return null;
  }

  return (
    <div className={clsx(styles.point, styles[status], className)}>
      {text}
    </div>
  );
}
