import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import styles from './index.module.scss';

interface LoadingProps {
  size?: number;
  className?: string;
  absolute?: boolean;
}

function Loading({
  size: fontSize = 24,
  className = undefined,
  absolute = false,
}: LoadingProps) {
  const antIcon = <LoadingOutlined style={{ fontSize }} spin />;

  return (
    <div className={clsx(styles.loading, className)}>
      <div className={clsx(styles.pretty, { [styles.absolute]: absolute })}>
        <Spin indicator={antIcon} />
      </div>
    </div>
  );
}

export default Loading;
