import {
  createContext, ReactNode, useContext, useEffect, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useKeycloak } from '@react-keycloak/web';
import { CollectionByIdGet, useCollectionByIdGet } from '../../../../../hooks/api/collection';
import { FetchGetId } from '../../../../../hooks/fetch';

interface CollectProviderProps {
  fetch: () => void;
  collection: Partial<FetchGetId<CollectionByIdGet, string>>;
}

const defaultValue: CollectProviderProps = {
  fetch: () => {
    // empty
  },
  collection: {
    data: {
      id: '',
      name: '',
      description: '',
      assets: [],
      createdAt: '',
      updatedAt: dayjs(),
    },
    response: undefined,
    loading: false,
    error: null,
  },
};

export const CollectContext = createContext<CollectProviderProps>(defaultValue);

function CollectProvider({ children }: { children: ReactNode }) {
  const { keycloak, initialized } = useKeycloak();
  const { id: collectionId } = useParams();
  const collectionByIdGet = useCollectionByIdGet();

  const fetch = () => {
    collectionByIdGet.fetch(undefined, collectionId);
  };

  useEffect(() => {
    if (keycloak?.token) {
      fetch();
    }
  }, [initialized, keycloak?.token]);

  const memoizedValue = useMemo(() => ({
    collection: collectionByIdGet,
    fetch,
  }), [
    collectionByIdGet.loading,
    collectionByIdGet.response,
    collectionByIdGet.error,
    collectionId,
  ]);

  return (
    <CollectContext.Provider value={memoizedValue}>
      {children}
    </CollectContext.Provider>
  );
}

export default CollectProvider;

export const useCollectProvider = (): CollectProviderProps => useContext(CollectContext);
