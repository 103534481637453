import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx, { ClassValue } from 'clsx';
import { useTranslation } from 'react-i18next';
import Point from '../../../../Common/Point';
import ImgBackground from '../../../../Common/ImgBackground';

import styles from './index.module.scss';

export interface ItemProps {
  id: string;
  to?: string;
  url: string;
  gridSize: string;
  name: string;
  description?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode | React.ReactNode[];
  className?: string | ClassValue;
  nameStretch?: boolean
}

export default function Item({
  id, to, url, name, gridSize, description, children, actions, className, nameStretch = false,
}: ItemProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <NavLink
      to={to || `${id}`}
      className={clsx(styles.item, className)}
      style={{ gridTemplateColumns: gridSize }}
    >
      <ImgBackground url={url || '/resource/images/asset.png'} className={styles.img} />

      <div className={styles.data}>
        <div className={styles.name}>
          <div className={clsx(styles.text, { [styles.stretch]: nameStretch })}>
            {name}
          </div>

          {actions ? (
            <div role="none" className={styles.actions} onClick={(e) => e.stopPropagation()}>
              {actions}
            </div>
          ) : null}
        </div>

        <div className={styles.description}>
          {description}
        </div>

        {children}

        <Point
          status="orange"
          className={styles.line}
          text={(
            <>
              {t('status.notSet')}
              <br />
              {t('status.notSet2')}
            </>
          )}
        />
      </div>

      <Point
        status="orange"
        className={styles.line}
        text={(
          <>
            {t('status.notSet')}
            <br />
            {t('status.notSet2')}
          </>
        )}
      />
    </NavLink>
  );
}
