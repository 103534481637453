import React, { useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ShareIcon } from '../../../../../Common/Icon';
import ShareModal from '../../../../../Common/Modal/Collections/Share';
import { AssetByIdGet } from '../../../../../../hooks/api/asset';

export interface ShareProps {
  id?: string;
  assets: AssetByIdGet[];
  description?: string;
}

export default function Share({ id, assets, description }: ShareProps): React.ReactNode {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const ok = () => {
    setIsModalOpen(false);
  };

  const cancel = () => {
    setIsModalOpen(false);
  };

  if (!id || assets.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        icon={<ShareIcon />}
        // type="primary"
        size="large"
        onClick={(e) => {
          e.preventDefault();
          showModal();
        }}
      >
        {t('share')}
      </Button>
      <ShareModal
        id={id}
        assets={assets}
        description={description}
        isModalOpen={isModalOpen}
        handleOk={ok}
        handleCancel={cancel}
      />
    </>
  );
}
