import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import NoData from '../../Common/Wrapper/NoData';

import styles from './index.module.scss';

export interface NoResultProps {
  style?: CSSProperties;
}

export default function NoResult({ style }: NoResultProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className={styles.notResult} style={style}>
      <NoData
        type="noResult"
        title={(
          <>
            <span style={{ fontWeight: 300 }}>
              {t('noData.noResults.title')}
            </span>
            <br />
            <b>{t('noData.noResults.found')}</b>
          </>
        )}
        text={t('noData.noResults.sorry')}
      />
    </div>
  );
}
