import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';

import { useMediaQuery } from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CloseIcon, SearchIcon } from '../../../Common/Icon';
import useComponentDidUpdate from '../../../../hooks/componentDidUpdate';
import { useFilter } from '../../../Pages/Home/Context/filter';
import { AnyObject } from '../../../../types';

import styles from './index.module.scss';

export default function Search(): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isPhone = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const { state: { search, ...state } } = useFilter();
  const [_, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<null | string | undefined>(search || '');
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<any>();

  useEffect(() => {
    if (search !== value) {
      setValue(search);
    } else if (!search) {
      setValue(null);
      setOpen(false);
    }
  }, [search]);

  const clearSearch = () => {
    const { search: s, ...data } = state;

    setSearchParams({ ...data, page: 1 } as AnyObject);
  };

  const onSubmit = () => {
    if (window.location.pathname !== '/') {
      navigate('/');
    }

    if (value) {
      setSearchParams({ search: value || '', page: 1 } as AnyObject);
    } else {
      clearSearch();
    }
  };

  return !isOpen ? (
    <a
      role="search"
      className={styles.search}
      onClick={(e) => {
        e.preventDefault();
        setOpen(true);
        setTimeout(() => {
          if (ref.current) {
            ref.current.querySelector('input')?.focus();
          }
        }, 100);
      }}
    >
      <SearchIcon />
    </a>
  ) : (
    <form
      className={styles.search}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      ref={ref}
    >
      {isPhone ? (
        <div
          role="none"
          className={styles.close}
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
        >
          <CloseIcon />
        </div>
      ) : null}

      <Input
        placeholder={t('whatAreYouLookingFor')}
        allowClear={isPhone ? false : {
          clearIcon: (
            <CloseIcon
              onClick={(e) => {
                e.preventDefault();
                clearSearch();
                setTimeout(() => {
                  setOpen(false);
                }, 10);
              }}
            />
          ),
        }}
        onChange={(e) => setValue(e.target.value)}
        value={value || ''}
      />
      <div
        className={styles.submit}
        role="search"
        onClick={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SearchIcon />
      </div>
    </form>
  );
}
