import React, { useEffect } from 'react';

import clsx, { ClassValue } from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { LangIcon } from '../../../Common/Icon';
import { LANG_LIST, LANG_LIST_FULL, useLang } from '../../../../context/lang';

import styles from './index.module.scss';

interface LangProps {
  icon?: boolean;
  className?: string | ClassValue;
  children?: React.ReactNode
}

export default function Lang({ icon = true, className, children }: LangProps): React.ReactNode {
  const {
    lang: { value: lang },
    setLang,
  } = useLang();
  const numberLang = LANG_LIST.indexOf(lang);
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  useEffect(() => {
    if (!lang) {
      setLang(LANG_LIST[0]);
    }
  }, [lang]);

  return (
    <a
      role="none"
      className={clsx(styles.lang, className)}
      onClick={(e) => {
        e.preventDefault();

        if (LANG_LIST[0] === lang) {
          setLang(LANG_LIST[1]);
        } else {
          setLang(LANG_LIST[0]);
        }
      }}
    >
      {icon ? (
        <LangIcon />
      ) : null}
      <div style={{ paddingRight: 8 }}>
        {isMobile ? LANG_LIST_FULL[numberLang] : LANG_LIST[numberLang]}
      </div>
      {children}
    </a>
  );
}
