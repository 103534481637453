import React from 'react';
import Layout from '../../components/Layout';
import ContentImprint from '../../components/Pages/Imprint';

export default function Imprint(): React.ReactNode | null {
  document.title = 'Imprint';

  return (
    <Layout className="center">
      <ContentImprint />
    </Layout>
  );
}
