import React, { useEffect, useState } from 'react';
import clsx, { ClassValue } from 'clsx';

import { Collapse } from 'antd';
import { useLocation } from 'react-router-dom';
import { ArrowRightIcon } from '../../../Common/Icon';

import styles from './index.module.scss';

export interface CollapseInfoProps {
  type?: string;
  title: string;
  className?: string | ClassValue;
  children: React.ReactNode;
}

export default function CollapseInfo({
  children, type, title, className = '',
}: CollapseInfoProps): React.ReactNode {
  const [isActive, setActive] = useState(false);
  const { hash } = useLocation();
  const active = hash.toLowerCase() === (`#${type?.toLowerCase().replace(' ', '')}`);

  useEffect(() => {
    if (hash && active) {
      setActive(true);

      setTimeout(() => {
        document.querySelector('.ant-layout .scroll')?.scrollTo({
          top: 99999,
          behavior: 'smooth',
        });
        document.body.scrollTo({
          top: 99999,
          behavior: 'smooth',
        });
      }, 250);
    }
  }, [hash]);

  useEffect(() => {
    if (isActive === false && active) {
      window.location.hash = '#';
    }
  }, [isActive]);

  return (
    <div className={clsx(styles.collapseInfo, className)}>
      <Collapse
        destroyInactivePanel={false}
        expandIcon={ArrowRightIcon}
        activeKey={isActive ? ['1'] : []}
        onChange={() => {
          setActive(!isActive);
        }}
        expandIconPosition="end"
        items={[{
          key: '1',
          label: title,
          children,
        }]}
      />
    </div>
  );
}
