import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NoData from '../../Common/Wrapper/NoData';
import { BigArrowIcon } from '../../Common/Icon';

import styles from './index.module.scss';

export default function NotFound(): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className={styles.notFound}>
      <NoData
        type="noFound"
        title={(
          <>
            <span style={{ fontWeight: 300 }}>
              {t('noData.noFound.title')}
            </span>
            <br />
            <b>{t('noData.noFound.title2')}</b>
          </>
        )}
        text={t('noData.noFound.text')}
        link={(
          <NavLink to="/" className={styles.link}>
            <BigArrowIcon />
            {t('noData.noFound.link')}
          </NavLink>
        )}
      />
    </div>
  );
}
