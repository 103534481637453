import React, { memo, useMemo } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useFilter } from '../../Pages/Home/Context/filter';
import { AssetFilterGet } from '../../../hooks/api/asset';
import { CloseIcon } from '../Icon';
import { AnyObject } from '../../../types';
import { FilterKey, getFilterText, prettyFormatFilterText } from '../Filter';
import { useLang } from '../../../context/lang';

import styles from './index.module.scss';

export default memo(() => {
  const { lang: { value: lang } } = useLang();
  const [_, setSearchParams, paramsWithoutTableProps] = useSearchParams();
  const { allFilter, state } = useFilter();
  const { t } = useTranslation();

  const list = useMemo(
    () => (!allFilter.data
      ? []
      : Object.keys(paramsWithoutTableProps)
        .map((name) => ((paramsWithoutTableProps[name]
          && Array.isArray(paramsWithoutTableProps[name])
          ? paramsWithoutTableProps[name]
          : [paramsWithoutTableProps[name]]
          ) as string[])
          .map((value: string) => {
            if (allFilter.data && allFilter.data[name]) {
              const item = allFilter.data[name]
                .find((f: AssetFilterGet) => (typeof f === 'string' ? f === value : f.id === value));

              if (item) {
                return (
                  <div key={`${name}-${value}`} className={styles.item}>
                    {prettyFormatFilterText(getFilterText(item, lang), name as FilterKey)}

                    <div
                      role="none"
                      className={styles.remove}
                      onClick={(e) => {
                        e.preventDefault();

                        setSearchParams({
                          ...state,
                          page: 1,
                          [name]: (
                            (state[name] && Array.isArray(state[name]) ? state[name] : [state[name]])
                              .filter((element: string) => element !== (typeof item === 'string' ? item : item.id))
                          ),
                        } as AnyObject);
                      }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                );
              }
            }

            return null;
          }).filter((item) => item))
        .reduce((acc, filters) => {
          filters.forEach((item) => acc.push(item));

          return acc;
        }, [])),
    [allFilter.data, paramsWithoutTableProps, state, lang],
  );

  if (!allFilter.data) {
    return null;
  }

  if (list.length === 0) {
    return null;
  }

  return (
    <div className={styles.selectedFilters}>
      <Button
        size="large"
        className={styles.clear}
        onClick={(e) => {
          e.preventDefault();
          setSearchParams({ page: '1' });
        }}
      >
        {t('clearAll')}
      </Button>
      <div className={styles.list}>
        {list}
      </div>
      <Button
        size="large"
        className={styles.clear}
        onClick={(e) => {
          e.preventDefault();
          setSearchParams({ page: '1' });
        }}
      >
        {t('clearAll')}
      </Button>
    </div>
  );
});
