import React, { useEffect, useMemo, useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import {
  Checkbox, Input, Pagination, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  ArrowBottomIcon, ArrowLeftIcon, ArrowRightIcon, ArrowTopIcon, CloseIcon, SearchIcon,
} from '../Icon';
import { getMinPageSize, useFilter } from '../../Pages/Home/Context/filter';
import { useSearchParams } from '../../../hooks/useSearchParams';
import useComponentDidUpdate from '../../../hooks/componentDidUpdate';
import { AnyObject } from '../../../types';
import { capitalizeFirstLetter } from '../../../utils';
import { AssetFilterItem } from '../../../types/asset';
import { useLang } from '../../../context/lang';
import Loading from '../Loading';

import styles from './index.module.scss';

export type FilterKey = 'mediaType' |
  'types' | 'keywords' | 'restriction' | 'format' | 'series' |
  'systems' | 'language' | 'brands' | 'publicationType' |
  'publicationYear' | 'statements' | 'persons' | 'locations' |
  'surroundings' | 'references' | 'tools' | 'events' | 'campaigns';

const filterOrder: Array<FilterKey> = [
  'mediaType',
  'types',
  'keywords',
  'restriction',
  'format',
  'series',
  'systems',
  'language',
  'brands',
  'publicationType',
  'publicationYear',
  'statements',
  'persons',
  'locations',
  'surroundings',
  'references',
  'tools',
  'events',
  'campaigns',
];

export function getFilterText(
  option: string | {
  name?: string;
  nameEn?: string;
  nameDe?: string;
  value?: string;
},
  lang: string,
  name?: string,
) {
  return typeof option === 'string'
    ? option
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    : option[`name${capitalizeFirstLetter(lang)}`] || option.name || name || option?.value;
}

export function prettyFormatFilterText(text: string, name: FilterKey) {
  if ([
    'publicationYear',
  ].includes(name)) {
    return dayjs(text).format('YYYY');
  }

  if ([
    'mediaType',
  ].includes(name)) {
    return capitalizeFirstLetter(text.replaceAll('.', ' ').trim());
  }

  if ([
    'format',
  ].includes(name)) {
    return text.toLowerCase();
  }

  if ([
    'language',
  ].includes(name)) {
    return text.toLowerCase();
  }

  // eslint-disable-next-line no-useless-escape
  return capitalizeFirstLetter(text.replace(/[_\-]+/gim, ' '));
}

export const restrictionDe: { [key: string]: string } = {
  green: 'Grün',
  orange: 'Orange',
  red: 'Rot',
};

interface OptionProps {
  name: FilterKey;
  onClick: (option: string | AssetFilterItem, checked: boolean) => void;
  option: string | AssetFilterItem;
  checked?: boolean;
  disabled?: boolean;
}

function Option({
  disabled = false,
  name,
  checked: defaultChecked = false,
  option,
  onClick,
}: OptionProps) {
  const { lang: { value: lang } } = useLang();
  const [checked, setChecked] = useState(defaultChecked);

  useComponentDidUpdate(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <div
      role="none"
      className={styles.option}
      onClick={(e) => {
        e.preventDefault();
        if (disabled) {
          return;
        }

        onClick(option, !checked);
        setChecked(!checked);
      }}
    >
      <Checkbox
        checked={checked}
        disabled={disabled}
      >
        {prettyFormatFilterText(
          getFilterText(
            lang === 'de' && name === 'restriction' && typeof option === 'string'
              ? restrictionDe[option as string] || option : option,
            lang,
          ),
          name,
        )}
      </Checkbox>
    </div>
  );
}

interface MemoSelectFilterListProps {
  list: {
    name: FilterKey;
    options: string[] | AssetFilterItem[];
  }[]
}

function MemoSelectFilterList({ list }: MemoSelectFilterListProps) {
  const { lang: { value: lang } } = useLang();

  return useMemo(() => (list.length > 0 ? (
    list.map(({ name, options }) => (
      <SelectFilter
        key={name}
        name={name}
        options={options}
      />
    ))
  ) : null), [list, lang]);
}

interface SelectFilterProps {
  name: FilterKey;
  options: string[] | AssetFilterItem[]
}

function SelectFilter({ name, options }: SelectFilterProps) {
  const { t } = useTranslation();
  const { lang: { value: lang } } = useLang();
  const { filterAvailable } = useFilter();
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [_, setSearchParams, searchParams] = useSearchParams({}, false);

  const list = useMemo(() => {
    const listOptions = options.map((option) => ({
      option,
      disabled: filterAvailable?.data && filterAvailable?.data[name]
        ? !filterAvailable?.data[name].includes(typeof option === 'string' ? option : option.id) : true,
    }));

    listOptions.sort(({ disabled }) => (disabled ? 1 : -1));

    return listOptions;
  }, [options, filterAvailable?.data]);

  const isDisabled = useMemo(() => !list.some(({ disabled }) => disabled === false), [list]);

  useEffect(() => {
    if (isDisabled) {
      setIsOpen(false);
    }
  }, [isDisabled]);

  const listOption = useMemo(() => (
    (search
      ? list.filter(({ option }) => getFilterText(option, lang)
        .toLowerCase()
        .includes(search.trim().toLowerCase()))
      : list).map(({ disabled, option }) => {
      const key = typeof option === 'string' ? option : option.id;

      return (
        <Option
          key={key}
          name={name}
          disabled={disabled}
          checked={searchParams[name] && Array.isArray(searchParams[name])
            ? searchParams[name].includes(key) : searchParams[name] === key}
          option={option}
          onClick={(opt, checked) => {
            // eslint-disable-next-line no-nested-ternary
            const beforeValue = (Array.isArray(searchParams[name])
              ? searchParams[name] : (searchParams[name] ? [searchParams[name]] : [])) as string[];

            if (checked) {
              setSearchParams({
                ...searchParams,
                page: '1',
                [name]: (searchParams[name] ? [...beforeValue, key] : [key]) as any,
              });
            } else if (searchParams[name]) {
              setSearchParams({
                ...searchParams,
                page: '1',
                [name]: beforeValue.filter((v) => v !== key),
              });
            }
          }}
        />
      );
    })
  ), [list, search]);

  return useMemo(() => (
    <div key={name} className={styles.row}>
      <div
        role="none"
        className={clsx(styles.name, {
          [styles.open]: isOpen,
          [styles.disabled]: isDisabled,
        })}
        onClick={(e) => {
          e.preventDefault();

          if (!isDisabled) {
            setIsOpen(!isOpen);
          }
        }}
      >
        {t(`filter.${name}`)}
        {isOpen ? (
          <ArrowBottomIcon />
        ) : (
          <ArrowRightIcon />
        )}
      </div>
      <div
        className={clsx(styles.list, { [styles.open]: isOpen })}
        style={{ height: (Math.min(options.length, 6) + (options.length > 6 ? 1 : 0)) * 40 + 8 * 2 }}
      >
        {options.length > 6 ? (
          <div className={styles.search}>
            <Input
              allowClear={{
                clearIcon: <CloseIcon style={{ margin: '2px 0 -2px 0', fontSize: 18 }} />,
              }}
              className={styles.search}
              placeholder={t('search.placeholder')}
              suffix={<SearchIcon />}
              onChange={(e) => setSearch(e.target.value)}
              size="large"
            />
          </div>
        ) : null}

        <div className={styles.scroll}>
          {isOpen ? listOption : null}
        </div>
      </div>
    </div>
  ), [isOpen, isDisabled, listOption]);
}

export interface ContentWrapperProps {
  children?: React.ReactNode
  className?: string | ClassValue;
}

export default function Filter({
  children,
  className = '',
}: ContentWrapperProps): React.ReactNode {
  const { t } = useTranslation();
  const { state: { search } } = useFilter();
  const [value, setValue] = useState<string | undefined>(search);
  const [_, setSearchParams, searchParams, defaultSetSearchParams] = useSearchParams({}, false);
  const {
    state, total, allFilter: { data, error, loading }, isFilterOpen, setFilterOpen,
  } = useFilter();
  const list = useMemo(() => {
    if (data && !error && !loading) {
      return filterOrder.map((key) => ({
        name: key,
        options: data[key] as string[] | AssetFilterItem[],
      })).filter(({ options }) => options
        .some((option) => (typeof option === 'string' ? option : (option?.value || option?.text))));
    }

    return [];
  }, [data]);

  useEffect(() => {
    if (search !== value) {
      setValue(search);
    } else if (!search) {
      setValue('');
    }
  }, [search]);

  const clearSearch = () => {
    const { search: s, ...st } = state;

    setSearchParams({ ...st, page: 1 } as AnyObject);
  };

  const onSubmit = () => {
    setSearchParams({ ...state, search: value || '', page: 1 } as AnyObject);
  };

  return (
    <div className={clsx({ filterOpen: isFilterOpen }, { [styles.filterOpen]: isFilterOpen })}>
      <div className={styles.filter}>
        <div
          className={styles.select}
          role="none"
          onClick={(e) => {
            e.preventDefault();
            setFilterOpen(false);
          }}
        >
          <ArrowTopIcon />
          {t('filter.name')}

          {Object.keys(searchParams).some((key) => !['page', 'pageSize', 'orderBy', 'search'].includes(key)) ? (
            <div
              role="none"
              className={styles.reset}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                defaultSetSearchParams({});
              }}
            >
              {t('resetAll')}
            </div>
          ) : null}
        </div>

        <span className={styles.result}>
          {t('result')}
          :
          {' '}
          {total}
        </span>

        <form
          style={{ width: 'calc(100% - 16px * 2)' }}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Input
            className={styles.search}
            placeholder={t('search.placeholder')}
            suffix={(
              <SearchIcon
                style={{
                  cursor: 'pointer', padding: 10, margin: -11, width: 40, height: 40,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              />
            )}
            allowClear={{
              clearIcon: (
                <CloseIcon
                  style={{
                    margin: '-11px -10px -15px -11px',
                    padding: '11px',
                    width: 40,
                    height: 40,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    clearSearch();
                  }}
                />
              ),
            }}
            onChange={(e) => setValue(e.target.value)}
            value={value || ''}
            size="large"
          />
        </form>

        {loading ? (
          <Loading />
        ) : null}

        <MemoSelectFilterList list={list} />
      </div>

      <div className={styles.wrp}>
        <div className={clsx(styles.header, { [styles.filterOpen]: isFilterOpen }, className)}>
          {isFilterOpen ? null : (
            <div
              role="none"
              className={styles.select}
              onClick={(e) => {
                e.preventDefault();
                setFilterOpen(true);
              }}
            >
              <ArrowBottomIcon />
              {t('filter.name')}
            </div>
          )}

          <span className={clsx(styles.result, styles.mobileHide)}>
            {t('result')}
            :
            {' '}
            {total}
          </span>

          <div
            className={clsx(
              styles.tabletHide,
              { [styles.hide]: state.pageSize && total && total < state.pageSize },
            )}
          >
            <Pagination
              onChange={(item) => setSearchParams({
                ...searchParams,
                page: item.toString(),
              })}
              current={state.page}
              total={total}
              pageSize={state.pageSize}
              showSizeChanger={false}
              hideOnSinglePage
              showTitle={false}
              nextIcon={<ArrowRightIcon />}
              prevIcon={<ArrowLeftIcon />}
            />
          </div>

          <div className={styles.options}>
            <div className={styles.perpage}>
              <Select<number>
                suffixIcon={<ArrowBottomIcon />}
                onChange={(item) => setSearchParams({
                  ...searchParams,
                  pageSize: Math.max(getMinPageSize(), item).toString(),
                })}
                value={state.pageSize}
                size="large"
                options={[{
                  value: 12,
                  label: '12',
                },
                {
                  value: 24,
                  label: '24',
                },
                {
                  value: 48,
                  label: '48',
                },
                {
                  value: 72,
                  label: '72',
                },
                {
                  value: 96,
                  label: '96',
                },
                {
                  value: 120,
                  label: '120',
                }].filter(({ value: item }) => item >= getMinPageSize())}
              />
            </div>
            <div className={styles.order}>
              <Select
                suffixIcon={<ArrowBottomIcon />}
                onChange={(item) => setSearchParams({
                  ...searchParams,
                  orderBy: item.toString(),
                })}
                value={state.orderBy}
                size="large"
                options={[{
                  value: 'From newest to oldest',
                  label: t('order.from-new-to-old'),
                },
                {
                  value: 'From oldest to newest',
                  label: t('order.from-old-to-new'),
                },
                {
                  value: 'From 0 to 9, A-Z',
                  label: t('order.from-0-9'),
                },
                {
                  value: 'From 9 to 0, Z-A',
                  label: t('order.from-9-0'),
                }]}
              />
            </div>
          </div>

          <div
            className={clsx(
              styles.tabletShow,
              { [styles.hide]: state.pageSize && total < state.pageSize },
            )}
          >
            <Pagination
              onChange={(item) => setSearchParams({
                ...searchParams,
                page: item.toString(),
              })}
              current={state.page}
              total={total}
              pageSize={state.pageSize}
              showSizeChanger={false}
              hideOnSinglePage
              showTitle={false}
              nextIcon={<ArrowRightIcon />}
              prevIcon={<ArrowLeftIcon />}
              totalBoundaryShowSizeChanger={1}
            />
          </div>

          <span className={clsx(styles.result, styles.mobileShow)}>
            {t('result')}
            :
            {' '}
            {total}
          </span>
        </div>

        {children}
      </div>
    </div>
  );
}
