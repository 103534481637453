import React, {
  memo,
  useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { ArrowTopIcon } from '../Icon';

import styles from './index.module.scss';

interface MoveToTopProps {
  className?: string;
  min?: number;
  max?: number;
}

function MoveToTop({
  className = undefined,
  min,
  max,
}: MoveToTopProps) {
  const isMax = useMediaQuery({
    query: `(min-width: ${max}px)`,
  });
  const isMin = useMediaQuery({
    query: `(max-width: ${min}px)`,
  });

  if ((max && isMax) || (min && isMin)) {
    return null;
  }

  return <MoveToTopElement className={className} />;
}

const MoveToTopElement = memo(({ className }: MoveToTopProps) => {
  const ref = useRef<any>();
  const [isShowScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const onScroll = ({ target }: any) => {
      const footerHeight = (document.querySelector('#footer')?.getBoundingClientRect()?.height || 156) + 40;
      const scrollTop = target?.scrollTop || document.documentElement.scrollTop;
      const scrollHeight = target?.scrollHeight || document.documentElement.scrollHeight;
      const targetHeight = target?.getBoundingClientRect
        ? target?.getBoundingClientRect()?.height
        : window.innerHeight;

      setShowScrollTop(scrollTop > 150
        // eslint-disable-next-line no-unsafe-optional-chaining
        && scrollHeight - scrollTop - targetHeight >= -9999);

      if (ref.current) {
        const top = (scrollHeight || 0) - (scrollTop || 0) - (targetHeight || 0) + 40;

        if ((top > footerHeight ? 0 : footerHeight - top) <= 0) {
          ref.current.classList.add(styles.fixed);
          ref.current.style.bottom = '';
        } else {
          ref.current.classList.remove(styles.fixed);
          ref.current.style.bottom = `${footerHeight}px`;
        }
      }
    };

    const scrollElement = document.querySelector('.ant-layout .scroll');

    if (scrollElement) {
      scrollElement.addEventListener('scroll', onScroll, false);
    }
    document.addEventListener('scroll', onScroll, false);

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', onScroll);
      }
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (!isShowScrollTop) {
    return null;
  }

  return (
    <div
      ref={ref}
      role="none"
      className={clsx(styles.moveToTop, className)}
      onClick={(e) => {
        e.preventDefault();
        document.querySelector('.ant-layout .scroll')?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        document.documentElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}

    >
      <ArrowTopIcon />
    </div>
  );
});

export default MoveToTop;
