import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AssetByIdGet } from '../../../../hooks/api/asset';
import { getFilterText } from '../../../Common/Filter';
import { useLang } from '../../../../context/lang';

import styles from './index.module.scss';

export interface KeywordsProps {
  asset?: AssetByIdGet;
  className?: string | ClassValue;
}

export default function Keywords({ asset, className = '' }: KeywordsProps): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lang: { value: lang } } = useLang();

  if (!asset?.keywords?.length) {
    return null;
  }

  return (
    <div className={clsx(styles.keywords, className)}>
      <div className={styles.name}>
        {t('filter.keywords')}
      </div>

      <div className={styles.list}>
        {asset.keywords.map((keyword) => (
          <div
            key={keyword.id}
            className={styles.item}
            role="none"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/?keywords=${keyword.id}`);
            }}
          >
            {getFilterText(keyword, lang)}
          </div>
        ))}
      </div>
    </div>
  );
}
