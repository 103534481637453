import React, { useEffect } from 'react';

import {
  Button, Form, Input, Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../Icon';
import { CollectionCreateForm, useCollectionCreate } from '../../../../../hooks/api/collection';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';

import styles from './index.module.scss';

interface CreateProps {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export default function Create({
  isModalOpen,
  handleOk,
  handleCancel,
}: CreateProps): React.ReactNode {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const collectionCreate = useCollectionCreate();
  const {
    loading, error, response, fetch,
  } = collectionCreate;

  useMessageError([collectionCreate]);
  useMessageSuccess([collectionCreate], t('create.collection.success'));

  useEffect(() => {
    if (!loading && !error && response) {
      form.resetFields();
      handleOk();
    }
  }, [response]);

  const handleSubmit = (formItems: CollectionCreateForm) => {
    fetch(formItems);
  };

  return (
    <Modal
      width={420}
      title={t('create.collection.title')}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      className={styles.modalCreate}
    >
      <div className={styles.content}>
        <Form<CollectionCreateForm>
          requiredMark={false}
          name="trigger"
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item
            label={t('name')}
            name="name"
            validateDebounce={500}
            rules={[{
              min: 2,
              required: true,
              message: t('required.name'),
            }, {
              max: 255,
              message: t('required.max255'),
            }]}
          >
            <Input placeholder={t('name')} size="large" />
          </Form.Item>

          <Form.Item
            label={t('descriptionOptional')}
            name="description"
            validateDebounce={500}
          >
            <Input.TextArea
              placeholder={t('description')}
              size="large"
              rows={1}
            />
          </Form.Item>
        </Form>
      </div>

      <div>
        <Button
          size="large"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
            form.resetFields();
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            form.submit();
          }}
          loading={loading}
        >
          {t('create.title')}
        </Button>
      </div>
    </Modal>
  );
}
