import React from 'react';
import Layout from '../../components/Layout';
import ContentCollections from '../../components/Pages/Collections';

export default function Collections(): React.ReactNode | null {
  document.title = 'Collections';

  return (
    <Layout className="center">
      <ContentCollections />
    </Layout>
  );
}
