import React, { useState } from 'react';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import './index.scss';

import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Button } from 'antd';
import Routes from './routes';
import AntdConfigProvider from './AntdConfigProvider';
import LangProvider from './context/lang';
import PrepareFileProvider from './context/prepareFile';
import { getDataInStorage, setDataInStorage } from './utils/storage';

dayjs.extend(localeData);

export default function App(): React.ReactNode {
  const [showCookies, setShowCookies] = useState(getDataInStorage('cookies')?.status || false);

  return (
    <ReactKeycloakProvider
      authClient={new Keycloak({
        realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
        url: process.env.REACT_APP_KEYCLOAK_URL || '',
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
      })}
    >
      <AntdConfigProvider>
        <LangProvider>
          <PrepareFileProvider>
            <Routes />
          </PrepareFileProvider>

          {showCookies ? null : (
            <div className="cookies">
              <div>
                <span>
                  This site uses cookies. By continuing to browse the site you are agreeing to our use of cookies.
                  →
                  {' '}
                  <a href="https://www.geberit.com/general/datenschutzerklaerung.html" target="_blank" rel="noreferrer">
                    privacy policy
                  </a>
                  {' '}
                  for more information.
                </span>
                <Button
                  size="large"
                  type="text"
                  onClick={() => {
                    setDataInStorage('cookies', {
                      status: true,
                    });
                    setShowCookies(true);
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          )}
        </LangProvider>
      </AntdConfigProvider>
    </ReactKeycloakProvider>
  );
}
