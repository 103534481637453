import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getDataInStorage, setDataInStorage } from '../utils/storage';
import { AnyObject } from '../types';

export const KEY = 'lang';
export const LANG_LIST = ['en', 'de'];
export const LANG_LIST_FULL = ['English', 'Germany'];

interface Lang {
  value: string;
}

interface LangProviderProps {
  lang: Lang;
  setLang: (value: string) => void;
}

const defaultValue: LangProviderProps = {
  lang: { value: LANG_LIST[0] },
  setLang: (value: string) => {},
};

const createCorrectState = (obj: AnyObject): Lang => {
  if (obj && obj.value && typeof obj.value === 'string') {
    return {
      value: LANG_LIST.includes(obj.value) ? obj.value : LANG_LIST[0],
    };
  }

  return {
    value: LANG_LIST[0],
  };
};

export const LangContext = createContext<LangProviderProps>(defaultValue);

function LangProvider({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation();
  const [state, setState] = useState<Lang>(createCorrectState(getDataInStorage(KEY)));

  useEffect(() => {
    const fn = () => {
      if (JSON.stringify(state) !== window.localStorage.getItem(KEY)) {
        const { value } = getDataInStorage(KEY);

        setState({
          value,
        });
      }
    };

    window.addEventListener('storage', fn, false);

    return () => window.removeEventListener('storage', fn);
  }, []);

  useEffect(() => {
    if (state && state.value) {
      i18n.changeLanguage(state.value);
    }
  }, [state]);

  const memoizedValue = useMemo(() => ({
    lang: state,
    setLang: (value: string) => {
      setState({
        value,
      });
      setDataInStorage(KEY, {
        value,
      });
    },
  }), [state]);

  return (
    <LangContext.Provider value={memoizedValue}>
      {children}
    </LangContext.Provider>
  );
}

export default LangProvider;

export const useLang = (): LangProviderProps => useContext(LangContext);
