import dayjs from 'dayjs';
import {
  DefaultFetchError,
  FetchCreate, FetchDelete, FetchGet, FetchGetId, FetchSuccess, FetchUpdate, PagingDataResponse, PagingParams,
  useFetchCreate, useFetchDelete, useFetchGet, useFetchGetId, useFetchUpdate,
} from '../fetch';
import { AssetByIdGet } from './asset';

export type CollectionGetParams = PagingParams

export interface CollectionGet {
  id: string;
  name: string;
  description: string;
  assets: AssetByIdGet[];
  assetsOrder?: string[];
  createdAt: string;
  updatedAt: string;
}

export interface CollectionPagingGet extends PagingDataResponse<CollectionGet> {
  assetsCount: number;
}

export const useCollectionGet = (): FetchGet<CollectionPagingGet, CollectionGetParams> => useFetchGet(
  'collection',
  {
    autoStart: false,
    startStateLoading: false,
    decorateData: (data) => ({
      ...data,
      data: data.data.map(({ assetsOrder, ...item }) => {
        let assets: AssetByIdGet[];

        if (assetsOrder?.length === item.assets.length) {
          assets = assetsOrder
            .map((_id) => item.assets
              .find(({ id }) => id === _id))
            .filter((d) => d !== undefined) as AssetByIdGet[];
        } else {
          assets = item.assets;
        }

        return {
          ...item,
          assets,
        };
      }),
    }),
  },
);

export interface CollectionByIdGet extends Omit<CollectionGet, 'updatedAt'> {
  updatedAt: dayjs.Dayjs;
}

export const useCollectionByIdGet = (): FetchGetId<CollectionByIdGet, string> => useFetchGetId(
  'collection',
  '',
  {
    autoStart: false,
    startStateLoading: true,
    latest: true,
    decorateData: (data) => ({
      ...data,
      description: data.description || '',
      updatedAt: dayjs(data.updatedAt),
    }),
  },
);

export interface CollectionResponse {
  id: string;

  name: string;

  description: string;

  // assets: GetOneCollectionResponseDto[];

  createdAt: string;

  updatedAt: string;
}

export interface CollectionCreateForm {
  name: string;
  description?: string;
}

export interface CollectionCreateProps extends CollectionCreateForm {
  assets?: string[];
}

export const useCollectionCreate = (): FetchCreate<
  CollectionResponse,
  DefaultFetchError,
  CollectionCreateProps
> => useFetchCreate(
  'collection',
  {
    startStateLoading: false,
  },
);

export interface CollectionUpdateProps extends Partial<CollectionCreateProps> {
  assets?: string[];
}

export const useCollectionUpdate = (id?: string): FetchUpdate<
  CollectionResponse,
  DefaultFetchError,
  CollectionUpdateProps
> => useFetchUpdate(
  'collection',
  id,
  {
    startStateLoading: false,
  },
);

export const useCollectionDelete = (id?: string): FetchDelete<
  FetchSuccess,
  DefaultFetchError
> => useFetchDelete('collection', id);

export default {
  useCollectionCreate,
};
