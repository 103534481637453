import React from 'react';
import Layout from '../../components/Layout';
import ContentShare from '../../components/Pages/Share';

export default function Share(): React.ReactNode | null {
  document.title = 'Share';

  return (
    <Layout className="center">
      <ContentShare />
    </Layout>
  );
}
