import React from 'react';
import Layout from '../../../components/Layout';
import Loading from '../../../components/Common/Loading';

function SignIn(): React.ReactNode {
  document.title = 'Sign in';

  return (
    <Layout>
      <Loading />
    </Layout>
  );
}

export default SignIn;
