import React, { useState } from 'react';

import { DownloadIcon } from '../../../../Common/Icon';
import DownloadModal from '../../../../Common/Modal/Collections/Download';
import { AssetByIdGet } from '../../../../../hooks/api/asset';

export interface DownloadProps {
  name: string;
  assets: AssetByIdGet[];
}

export default function Download({ assets, name }: DownloadProps): React.ReactNode {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const ok = () => {
    setIsModalOpen(false);
  };

  const cancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        role="none"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          showModal();
        }}
      >
        <DownloadIcon />
      </div>
      <DownloadModal
        name={name}
        assets={assets}
        isModalOpen={isModalOpen}
        handleOk={ok}
        handleCancel={cancel}
      />
    </>
  );
}
