import React, { useEffect, useState } from 'react';
import { Button, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import ContentWrapper from '../../Common/Content/Wrapper';
import Breadcrumbs from '../../Common/Breadcrumbs';
import {
  ArrowLeftIcon, ArrowRightIcon, PlusIcon,
} from '../../Common/Icon';
import NoCollection from '../NoCollection';
import Create from '../../Common/Modal/Collections/Create';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { getMinPageSize } from '../Home/Context/filter';
import { useCollectionGet } from '../../../hooks/api/collection';
import { useMessageError } from '../../../hooks/common';
import Loading from '../../Common/Loading';
import Download from './Modal/Download';
import Share from './Modal/Share';
import Delete from './Modal/Delete';
import Item from './Collection/Item';

import styles from './index.module.scss';

const pageSize = getMinPageSize();

export default function Collections(): React.ReactNode {
  const { t } = useTranslation();
  const [_, setSearchParams, searchParams] = useSearchParams({ page: '1' }, false);
  const [isModalOpenCreate, setIsModalOpenCreate] = useState(false);
  const collectionGet = useCollectionGet();

  useMessageError([collectionGet]);

  const fetchCollection = () => {
    collectionGet.fetch({
      page: Number.parseInt(searchParams.page as string, 10),
      pageSize,
    });
  };

  useEffect(fetchCollection, [searchParams.page]);

  const showModalCreate = () => {
    setIsModalOpenCreate(true);
  };

  const okCreate = () => {
    setIsModalOpenCreate(false);
    fetchCollection();
  };

  const cancelCreate = () => {
    setIsModalOpenCreate(false);
  };

  return (
    <ContentWrapper className={styles.content}>
      <Breadcrumbs
        loading={false}
        list={[
          {
            name: t('collections'),
          },
        ]}
      />

      <div className={styles.header}>
        <div className={styles.title}>
          {t('collections')}

          <div className={styles.tabletAndMore}>
            <Button
              icon={<PlusIcon />}
              type="primary"
              size="large"
              onClick={(e) => {
                e.preventDefault();
                showModalCreate();
              }}
            >
              {t('create.collection.new')}
            </Button>
          </div>
        </div>

        {collectionGet.data?.meta.itemCount ? (
          <div className={styles.counter}>
            {collectionGet.data?.meta.itemCount}
            {' '}
            {t('collections').toLowerCase()}
            ,
            {' '}
            {collectionGet.data?.assetsCount || 0}
            {' '}
            {collectionGet.data?.assetsCount > 1 ? t('mediaAssets') : t('mediaAsset')}
          </div>
        ) : null}

        <Button
          className={styles.onlyMobile}
          icon={<PlusIcon />}
          type="primary"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            showModalCreate();
          }}
        >
          {t('create.collection.new')}
        </Button>
        <Create
          isModalOpen={isModalOpenCreate}
          handleOk={okCreate}
          handleCancel={cancelCreate}
        />
      </div>

      {(collectionGet.data?.data
          && collectionGet.data?.data.length > 0
          && !collectionGet.error
      ) || collectionGet.loading ? (
        <>
          <div className={styles.wrp}>
            <div className={styles.list}>
              {collectionGet.data?.data.map(({
                id, name, description, assets,
              }) => (
                <Item
                  key={id}
                  id={id}
                  url={assets[0]?.urlPreview || assets[0]?.urlExport}
                  name={name}
                  gridSize="35% 1fr"
                  description={description}
                  actions={(
                    <>
                      {assets.length > 0 ? (
                        <Download
                          name={name}
                          assets={assets}
                        />
                      ) : null}
                      <Share
                        id={id}
                        assets={assets}
                        description={description}
                      />
                      <Delete
                        id={id}
                        length={collectionGet.data?.data?.length}
                        onSuccess={fetchCollection}
                      />
                    </>
                    )}
                >
                  <div style={{ paddingBottom: 8 }}>
                    {assets.length}
                    {' '}
                    {assets.length > 1 ? t('assets') : t('asset')}
                  </div>
                </Item>
              ))}
            </div>

            {collectionGet.loading ? (
              <Loading />
            ) : null}
          </div>

          <div className={styles.pagination}>
            <Pagination
              onChange={(item) => setSearchParams({
                ...searchParams,
                page: item.toString(),
              })}
              current={Number.parseInt((searchParams.page as string) || '1', 10)}
              total={collectionGet.data?.meta.itemCount}
              pageSize={pageSize}
              showSizeChanger={false}
              hideOnSinglePage
              showTitle={false}
              nextIcon={<ArrowRightIcon />}
              prevIcon={<ArrowLeftIcon />}
              totalBoundaryShowSizeChanger={1}
            />
          </div>
        </>
        ) : (
          <NoCollection showModalCreate={showModalCreate} />
        )}

      {/* <div className={styles.list}> */}
      {/*  /!* bla *!/ */}
      {/* </div> */}

    </ContentWrapper>
  );
}
