import React, { useState } from 'react';

import { TrashIcon } from '../../../../Common/Icon';
import DeleteModal from '../../../../Common/Modal/Collections/Delete';

export interface DeleteProps {
  id: string;
  length?: number;
  onSuccess?: () => void;
}

export default function Delete({ id, length, onSuccess }: DeleteProps): React.ReactNode {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const ok = () => {
    setIsModalOpen(false);

    if (onSuccess) {
      onSuccess();
    }
  };

  const cancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        role="none"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          showModal();
        }}
      >
        <TrashIcon />
      </div>
      <DeleteModal
        id={id}
        length={length}
        isModalOpen={isModalOpen}
        handleOk={ok}
        handleCancel={cancel}
      />
    </>
  );
}
